import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-heading-subtext',
  standalone: true,
  imports: [],
  templateUrl: './main-heading-subtext.component.html',
  styleUrl: './main-heading-subtext.component.css',
})
export class MainHeadingSubtextComponent {
  @Input() headingText: string = '';
  @Input() subText: string = '';
  @Input() extraClasses: string = '';
}
